export const menuAccount = [
  {
    name: 'Dados pessoais',
    path: 'profile',
  },
  {
    name: 'Endereços',
    path: 'addresses',
  },
  {
    name: 'Pedidos',
    path: 'orders',
  },
  {
    name: 'Cartões',
    path: 'cards',
  },
  {
    name: 'Autenticação',
    path: 'authentication',
  },
  {
    name: 'Trocas e Devoluções',
    path: 'my-returns',
  },
  {
    name: 'Histórico de Pedidos',
    path: 'order-history',
  },
  {
    name: 'Garantias e Serviços',
    path: 'warranty-and-services',
  },
  {
    name: 'Meus Créditos',
    path: 'my-credits',
  },
]
