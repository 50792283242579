import React from 'react'
import { navigate } from 'gatsby'
import type { WishlistType } from 'src/typings/wishlist'

import './wishlist-card.scss'
import WishlistProductListImages from './components/WishlistProductListImages'

interface IWishlistCard {
  list: WishlistType
}

function WishlistCard({ list }: IWishlistCard) {
  const baseClass = 'wishlistCard'
  const { productList, name } = list

  const navigateToListEvent = () => {
    navigate(`/wishlist/list/${list?.id}`)
  }

  return (
    <div
      className={`${baseClass}`}
      role="link"
      tabIndex={0}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          navigateToListEvent()
        }
      }}
      onClick={navigateToListEvent}
    >
      <div className={`${baseClass}__images`}>
        <div className={`${baseClass}__images-container`}>
          <WishlistProductListImages productList={productList} />
        </div>
      </div>
      <div className={`${baseClass}__text`}>
        <p className={`${baseClass}__text-title`}>{name}</p>
        <p className={`${baseClass}__text-subtitle`}>
          {list?.productList?.length} itens
        </p>
      </div>
    </div>
  )
}

export default WishlistCard
