import React from 'react'
import WishlistCard from 'src/components/wishlist/WishlistCard'
import type { WishlistType } from 'src/typings/wishlist'

import './wishlist-grid.scss'

interface IWishlistGrid {
  lists: WishlistType[]
}

function WishlistGrid({ lists }: IWishlistGrid) {
  const baseClass = 'wishlistGrid'

  return (
    <div className={`${baseClass}-wishlist-list`}>
      {lists?.map((list) => (
        <WishlistCard key={list.id} list={list} />
      ))}
    </div>
  )
}

export default WishlistGrid
