import React from 'react'
import { useProduct } from 'src/sdk/product/useProduct'
import type { WishlistProductType } from 'src/typings/wishlist'
import { Image } from 'src/components/ui/Image'
import { OrderImagesByRegex } from 'src/utils/OrderImagesByRegex'

import './wishlist-product-image.scss'

interface WishlistProductImageProps {
  product: WishlistProductType
}

export const WishlistProductImage = ({
  product,
}: WishlistProductImageProps) => {
  const baseClass = 'wishlistProductImage'
  const { data } = useProduct(product?.productId)

  if (!data?.product) {
    return <></>
  }

  const { name, image } = data?.product

  return (
    <div className={`${baseClass}-container`}>
      <Image
        className={`${baseClass}-container__image`}
        baseUrl={OrderImagesByRegex(image)?.[0]?.url}
        alt={name}
        aspectRatio={1}
      />
    </div>
  )
}
