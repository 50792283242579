import React from 'react'

import './wishlist-product-card-skeleton.scss'

function WishlistProductCardSkeleton() {
  const baseClass = 'wishlistCard'

  return (
    <>
      <div className={`${baseClass}__images-fallback`} />
      <div className={`${baseClass}__images-fallback`} />
      <div className={`${baseClass}__images-fallback`} />
      <div className={`${baseClass}__images-fallback`} />
    </>
  )
}

export default WishlistProductCardSkeleton
