import { Link } from 'gatsby'
import React from 'react'
import MyAccountUser from 'src/components/icons/MyAccountUser'
import { menuAccount } from 'src/utils/menuAccount'
import { useUserContext } from 'src/contexts/user-context'

import storeConfig from '../../../../store.config'
import './styles.scss'

const WishlistMenu = () => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const logoutAccount = () => {
    const { account } = storeConfig

    const logoutUrl =
      account === 'lojavivara'
        ? 'https://secure.vivara.com.br/api/vtexid/pub/logout?scope=lojavivara&returnUrl=https://secure.vivara.com.br/'
        : 'https://secureqa.vivara.com.br/api/vtexid/pub/logout?scope=qavivara&returnUrl=https://secureqa.vivara.com.br/'

    window.location.href = logoutUrl
  }

  const { user } = useUserContext()

  return (
    <div className="wishlist-menu__container">
      <div className="wishlist-menu__greeting-container">
        <MyAccountUser />
        {user ? (
          <p className="wishlist-menu__greeting">
            Olá!{' '}
            {user?.givenName ?? (
              <span className="wishlist-menu__greeting-user">
                {' '}
                {user?.email}{' '}
              </span>
            )}
          </p>
        ) : (
          <p className="sizeguide-menu__greeting">Olá!</p>
        )}
      </div>
      <div className="wishlist-menu__link-container">
        {menuAccount?.map((item) => (
          <a
            href={`https://secure.vivara.com.br/account#/${item.path}`}
            key={item.name}
            className="wishlist-menu__link"
          >
            {item.name}
          </a>
        ))}
        <Link to="/size-guide" className="wishlist-menu__link">
          Minhas Medidas
        </Link>
        <Link to="/wishlist" className="wishlist-menu__link-wishlist">
          Meus Favoritos
        </Link>
        <Link to="/life-lovers" className="wishlist-menu__link">
          Life Lover
        </Link>
        <button onClick={logoutAccount} className="wishlist-menu__link">
          Sair
        </button>
      </div>
    </div>
  )
}

export default WishlistMenu
