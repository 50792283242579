import React from 'react'
import type { WishlistProductType } from 'src/typings/wishlist'
import WishlistProductCardSkeleton from 'src/components/wishlist/WishlistProductCardSkeleton'

import { WishlistProductImage } from '../../../WishlistProductImage'

interface IWishlistProductListImages {
  productList: WishlistProductType[]
}

function WishlistProductListImages({
  productList,
}: IWishlistProductListImages) {
  const NUMBERSOFIMAGES = 4
  const baseClass = 'wishlistCard'
  const listSkeletonOrImages = [...Array(NUMBERSOFIMAGES)]

  return (
    <>
      {!productList?.length ? (
        <WishlistProductCardSkeleton />
      ) : (
        <>
          {listSkeletonOrImages?.map((_, index) =>
            productList?.[index] ? (
              <WishlistProductImage
                key={`ProductImage-${index}`}
                product={productList?.[index]}
              />
            ) : (
              <div
                key={`skeleton-${index}`}
                className={`${baseClass}__images-fallback`}
              />
            )
          )}
        </>
      )}
    </>
  )
}

export default WishlistProductListImages
