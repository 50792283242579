import React from 'react'
import IconButton from 'src/components/ui/VivaraIconButton'
import PlusOutlineIcon from 'src/images/svg/icon-plus-outline'

import './wishlist-create-button.scss'

interface ICreateWishlistButton {
  setIsCreateWishlistModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function CreateWishlistButton({
  setIsCreateWishlistModalOpen,
}: ICreateWishlistButton) {
  return (
    <IconButton
      aria-label="Criar lista"
      icon={<PlusOutlineIcon />}
      className="create-wishlist-btn"
      onClick={() => setIsCreateWishlistModalOpen(true)}
    >
      Criar lista
    </IconButton>
  )
}

export default CreateWishlistButton
