import React, { useState } from 'react'
import { useWishlistContext } from 'src/contexts/WishlistContext/wishlist-context'
import SingleInputModal from 'src/components/modal/SingleInputModal'
import MyAccountBody from 'src/components/common/MyAccountBody'

import CreateWishlistButton from '../WishlistCreateButton'
import WishlistGrid from '../WishlistGrid'

function WishlistContainer() {
  const { lists, createWishlist } = useWishlistContext()
  const [isCreateWishlistModalOpen, setIsCreateWishlistModalOpen] =
    useState(false)

  return (
    <MyAccountBody
      title="Meus Favoritos"
      onIconClickAnchor="/"
      iconType="close"
      moreActions={
        <CreateWishlistButton
          setIsCreateWishlistModalOpen={setIsCreateWishlistModalOpen}
        />
      }
    >
      <WishlistGrid lists={lists} />
      {isCreateWishlistModalOpen && (
        <SingleInputModal
          title="Dê um nome para sua lista"
          btnText="Salvar"
          isOpen={isCreateWishlistModalOpen}
          setIsOpen={setIsCreateWishlistModalOpen}
          modalAction={createWishlist}
        />
      )}
    </MyAccountBody>
  )
}

export default WishlistContainer
