import React, { useEffect } from 'react'
import WishlistContainer from 'src/components/wishlist/WishlistContainer'
import WishlistMenu from 'src/components/wishlist/WishlistMenu'
import { useUserContext } from 'src/contexts/user-context'

import storeConfig from '../../../store.config'

import './styles.scss'

const WishlistPage = () => {
  const { user } = useUserContext()

  useEffect(() => {
    if (!user) {
      window.location.href = `${storeConfig?.loginUrl}`
    }
  }, [user])

  useEffect(() => {
    window.insider_object = window.insider_object || {}

    window.insider_object.page = {
      type: 'Wishlist List',
      originalType: 'Wishlist List',
    }

    delete window.insider_object.wishlist
  }, [])

  return (
    <div className="wishlist-page__container">
      <aside className="wishlist-page__aside">
        <WishlistMenu />
      </aside>
      <WishlistContainer />
    </div>
  )
}

export default WishlistPage
